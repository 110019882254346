<template>
  <div class="tinymce-box">
    <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick">
    </editor>
  </div>
</template>

<script>
  import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
  import Editor from '@tinymce/tinymce-vue'
  import 'tinymce/themes/silver'
  // 编辑器插件plugins
  // 更多插件参考：https://www.tiny.cloud/docs/plugins/
  import 'tinymce/icons/default/icons.min.js' // 引入图标文件
  import 'tinymce/plugins/image' // 插入上传图片插件
  import 'tinymce/plugins/media' // 插入视频插件
  import 'tinymce/plugins/table' // 插入表格插件
  import 'tinymce/plugins/lists' // 列表插件
  import 'tinymce/plugins/code' // 列表插件
  import 'tinymce/plugins/wordcount' // 字数统计插件
  // import 'tinymce/plugins/indent2em' // 首行缩进插件
  // import 'tinymce/plugins/lineheight' // 行高插件

  export default {
    components: {
      Editor
    },
    name: 'tinymce',
    props: {
      value: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      plugins: {
        type: [String, Array],
        default: 'lists image media table wordcount indent2em lineheight code'
      },
      toolbar: {
        type: [String, Array],
        default: function () {
          return [
            ' undo redo |  formatselect fontselect fontsizeselect | alignleft aligncenter alignright alignjustify ',
            ' bold italic underline strikethrough forecolor backcolor | bullist numlist outdent indent | lists image media table | removeformat code'
          ]
        }
      }
    },
    data() {
      return {
        token: '',
        init: {
          language_url: '/tinymce/langs/zh_CN.js',
          language: 'zh_CN',
          skin_url: '/tinymce/skins/ui/oxide',
          // skin_url: 'tinymce/skins/ui/oxide-dark',//暗色系
          min_height: 500,
          plugins: this.plugins,
          toolbar: this.toolbar,
          branding: false,
          menubar: false,
          paste_data_images: true,
          automatic_uploads: true,
          file_picker_types: 'media',
          file_picker_callback: (callback, value, meta) => {
            if (meta.filetype == 'media') {
              let input = document.createElement('input'); //创建一个隐藏的input
              input.setAttribute('type', 'file');
              input.onchange = () => {
                var file = input.files[0];
                var xhr, formData;
                xhr = new XMLHttpRequest();
                xhr.withCredentials = false;
                xhr.open('POST', 'http://upload.qiniup.com');
                xhr.onload = function () {
                  console.log(xhr)
                  if (xhr.status !== 200) {
                    return;
                  }
                  callback('http://healthqnytp.ifufan.com/' + JSON.parse(xhr.responseText).key);
                };
                // console.log(callback.filename())
                // console.log(value)
                // console.log(meta)
                let key = `${new Date().getTime()}_${file.name}`
                formData = new FormData();
                formData.append('file', file, file.name);
                formData.append('token', this.token);
                formData.append('key', key);
                xhr.send(formData);


              }
              //触发点击
              input.click();
            }
          },
          // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
          // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
          images_upload_handler: (blobInfo, success, failure) => {
            var xhr, formData;
            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', 'http://upload.qiniup.com');
            xhr.onload = function () {
              console.log(xhr)
              if (xhr.status !== 200) {
                failure('HTTP Error: ' + xhr.status);
                return;
              }
              success('http://healthqnytp.ifufan.com/' + JSON.parse(xhr.responseText).key);
            };
            let key = `${new Date().getTime()}_${blobInfo.filename()}`
            formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            formData.append('token', this.token);
            formData.append('key', key);
            xhr.send(formData);
          }

        },
        myValue: this.value
      }
    },
    mounted() {
      this.getQnToken()
      tinymce.init({})
    },
    methods: {
      // 获取上传七牛云Token
      getQnToken() {
        this.$http.post('/qiniu/token').then(res => {
          if (res.data.code == 1) {
            this.token = res.data.data
          }
        })
      },
      // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
      // 需要什么事件可以自己增加
      onClick(e) {
        this.$emit('onClick', e, tinymce)
      },
      // 可以添加一些自己的自定义事件，如清空内容
      clear() {
        this.myValue = ''
      }
    },
    watch: {
      value(newValue) {
        this.myValue = newValue
      },
      myValue(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
</script>
<style scoped>

</style>